<template>
  <v-container>
    <base-section id="map" space="0">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d341.97210803559443!2d106.80597594590849!3d-6.2278330753990065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x2e69f15016dfa8d5%3A0x1932ce0709d82af4!2sSCBD%2C%20Gedung%20Treasury%20Tower%20%2C%20Kawasan%20District%208%20LOT%2028%2C%20Jl.%20Tulodong%20Atas%202%20No.28%2C%20RT.5%2FRW.3%2C%20Senayan%2C%20Kec.%20Kby.%20Baru%2C%20Kota%20Jakarta%20Selatan%2C%20Daerah%20Khusus%20Ibukota%20Jakarta%2012190!3m2!1d-6.227823099999999!2d106.8061427!5e0!3m2!1sen!2sid!4v1730212211368!5m2!1sen!2sid"
        width="100%"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </base-section>
  </v-container>
</template>

<script>
export default {
  name: "SectionMap",

  provide: {
    heading: { align: "center" },
  },
};
</script>
